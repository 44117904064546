import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";
import Seo from '../components/Seo/seo';
import { Layout } from '../components/Layout';
import Quote from '../components/Quote/QuoteView'
import ImageText from '../components/ImageText/ImageTextView';
import Travel from '../components/Travel/TravelView';

import '../localization/i18n';
import { graphql, Link, useStaticQuery } from 'gatsby';

import './index.css'
import { getImageByRelativePath } from '../shared/utilities';
import useWindowDimensions from '../hooks/useWindowDimensions';

import '../css/styles.css';

const IndexPage: FC = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'homePage' });
    const data: any = useStaticQuery(graphql`
    {
        Test: file(relativePath: {eq: "stock1.jpeg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test2: file(relativePath: {eq: "de_roze_duikbril.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test3: file(relativePath: {eq: "leven_als_een_local.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test4: file(relativePath: {eq: "out_of_office.jpeg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test5: file(relativePath: {eq: "stock5.jpeg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
       Test6: file(relativePath: {eq: "walvishaai.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 900)
        }
        relativePath
      }
      Test7: file(relativePath: {eq: "FOTO1.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 900)
        }
        relativePath
      }
     
     }
    `);

    let slidesAmount = 3
    const { width } = useWindowDimensions();
    if (width > 1200){
        slidesAmount = 3;
      }
      else{
        slidesAmount = 1;
      }


    function getBanner() {
        var banner
        if (width > 768) {
            banner = <StaticImage
                className={'banner'}
                src="../images/banner1.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />

        }
        else {
            banner = <StaticImage
                className={'banner'}
                src="../images/banner1_mobile.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
        }
        return banner
    }



    function getVideo(){
        var video;
        if(width > 768){
            video = <iframe id="ytplayer" width="1495" height="845" allowFullScreen={true}
            src="https://www.youtube.com/embed/FwOD0nIi93A?rel=0"
            ></iframe>
        }
        else{
            video = <iframe  id="ytplayer" width="340" height="195" allowFullScreen={true}
            src="https://www.youtube.com/embed/FwOD0nIi93A?rel=0"
            ></iframe>
        }
        return video;
    }

    function getTravelBlock(){
        var travelBlock;
        if (width > 768 && width < 1366){
            travelBlock =                 
            
            <div className='travel_block_container' style={{ display: 'flex', justifyContent: 'center', margin: '10rem 0' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <Travel
                    features={[t('travelblock1_feature1'), t('travelblock1_feature2'), t('travelblock1_feature3'), t('travelblock1_feature4'), t('travelblock1_button')]}
                    left_to_right={true}
                    price="2899"
                    duration="12"
                    image={getImageByRelativePath("out_of_office.jpeg", data)}
                    linkButton={<div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link state={{ fromHome: true, index: 4 }} style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/island-hopping/'>{t('travelblock1_button')}</Link>
                    </div>} />
                <Travel
                    features={[t('travelblock2_feature1'), t('travelblock2_feature2'), t('travelblock2_feature3'), t('travelblock2_feature4'), t('travelblock2_button')]}
                    left_to_right={true}
                    price="2499"
                    duration={ t('price') + "5"}
                    image={getImageByRelativePath("stock5.jpeg", data)}
                    linkButton={<div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/cruise/'>{t('travelblock2_button')}</Link>
                    </div>} />

                <Travel
                    
                    features={[t('travelblock3_feature1'), t('travelblock3_feature2'), t('travelblock3_feature3'), t('travelblock3_feature4'), t('travelblock3_button')]}
                    left_to_right={true}
                    price="2499"
                    duration="8"
                    image={getImageByRelativePath("walvishaai.jpg", data)}
                    linkButton={<div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link state={{ fromHome: true, index: 3 }} style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/island-hopping/'>{t('travelblock3_button')}</Link>
                    </div>} />
            </div>

        </div> 

        }

        else{
            travelBlock = 
            <div className='travel_block_container' style={{ display: 'flex', justifyContent: 'center', margin: '10rem 0' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <Travel
                    features={[t('travelblock1_header'), t('travelblock1_feature1'), t('travelblock1_feature2'), t('travelblock1_feature3'), t('travelblock1_feature4'),]}
                    left_to_right={true}
                    price="2899"
                    duration="12"
                    image={getImageByRelativePath("out_of_office.jpeg", data)}
                    linkButton={<div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link state={{ fromHome: true, index: 4 }} style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/island-hopping/'>{t('travelblock1_button')}</Link>
                    </div>} />
                <Travel
                    features={[t('travelblock2_header'), t('travelblock2_feature1'), t('travelblock2_feature2'), t('travelblock2_feature3'), t('travelblock2_feature4'),]}
                    left_to_right={true}
                    price="2100"
                    duration= { t('price') + "5"}
                    image={getImageByRelativePath("stock5.jpeg", data)}
                    linkButton={<div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/cruise/'>{t('travelblock2_button')}</Link>
                    </div>} />
            </div>
            <Travel
            
                features={[t('travelblock3_header'), t('travelblock3_feature1'), t('travelblock3_feature2'), t('travelblock3_feature3'), t('travelblock3_feature4'),]}
                left_to_right={false}
                price="2499"
                duration="8"
                image={getImageByRelativePath("de_roze_duikbril.jpg", data)}
                linkButton={<div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link state={{ fromHome: true, index: 3 }} style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/island-hopping/'>{t('travelblock3_button')}</Link>
                </div>} />
        </div>
        }

        return travelBlock;

    }







    const banner = getBanner();
    const video = getVideo();
    const travelBlock = getTravelBlock();
    return (

        <Layout>
            <Seo title={'home'} description={"Eilandhoppen of cruise? Hoe je je vakantie ook wilt invullen: wij maken er een onvergetelijke ervaring van."} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <div>
                <div style={{
                    position: 'relative',
                    textAlign: 'center',
                    width: '100%',
                }}>
                    {banner}
                    <h2 className='banner_header' >The closest to nature you will ever be</h2>
                </div>
                <div style={{
                    textAlign: 'center',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '1.5rem 0 1.5rem 0',
                    boxShadow: '0 0 10px rgb(72 72 72 / 16%)',
                    zIndex: '200',
                    backgroundColor: '#ffffff'
                }}>
                    <StaticImage
                        className={'palmleaf'}
                        style={{
                            position: 'absolute',
                            zIndex: '1', width: '23rem',
                            right: '0%',
                            bottom: '0',
                            opacity: '0.1',
                            transform: 'scaleX(-1)'
                        }}
                        src='../images/Palmleaf.png'
                        quality={95}
                        formats={['auto', 'webp', 'avif']}
                        loading='eager'
                        alt='Intro banner'
                    />

                    <div className='main_text' >
                    {t('intro')}
                        <p></p>
                        <div>{t('intro_slot')}</div>
                    </div>
                </div>

                <div className='quote_segment'>
                    <div className='quotes_container'>
                        <h3>“In the end we only regret the chances we didn't take”</h3>
                        <div className='quotes_row'>
                        <div className='swiper_container' >
                        <Swiper
                        slidesPerView={slidesAmount}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        >
                            <SwiperSlide><Quote quote_owner="Niels" quote={t('quote_niels')} /></SwiperSlide>
                            <SwiperSlide><Quote quote_owner="Mirjam" quote={t('quote_mirjam')} /></SwiperSlide>
                            <SwiperSlide><Quote quote_owner="Renzo" quote={t('quote_renzo')} /></SwiperSlide>
                            <SwiperSlide><Quote quote_owner="Meike" quote={t('quote_meike')} /></SwiperSlide>
                            <SwiperSlide><Quote quote_owner="Evelien" quote={t('quote_evelien')} /></SwiperSlide>
                            <SwiperSlide><Quote quote_owner="Mark" quote=' “Onze jongens van 18 en 20 jaar, zijn enorm door Waldemar geïnspireerd geraakt. Het enthousiasme waarmee Waldemar ons meenam door het wonderschone Galápagos maakte de dagen extra bijzonder. Wij kijken terug op de mooiste reis van ons leven!”' /></SwiperSlide>
                            <SwiperSlide><Quote quote_owner="Pim" quote=' “I had a great experience at the Galapagos islands. Felice and Waldemar are very very nice and hospitable. I think this is the best way to explore the islands in a fun and adventurous way.”' /></SwiperSlide>
                            <SwiperSlide><Quote quote_owner="Luuk" quote="“Amazing tours, great guide and overall we've had wonderful experiences. If you're looking for the best way to explore Galapagos, you've come to the right place. ”" /></SwiperSlide>
                        </Swiper>
                    </div>
                        </div>
                    </div>

                </div>

                <ImageText image={getImageByRelativePath("leven_als_een_local.jpg", data)} header_text={t('image_textblock1_header')} hasButton={[true, "/about/", t('image_textblock1_button')]} image_left={false} text={t('image_textblock1_content')} />
                <h3>“Be open to it being way better than you ever imagined”</h3>
                <ImageText image={getImageByRelativePath("FOTO1.jpg", data)} header_text={t('image_textblock2_header')} hasButton={[true, "/contact/", t('image_textblock2_button')]} image_left={true} text={t('image_textblock2_content')} />
                
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10rem'}}>
                    {video}
                </div>

                
                {travelBlock}



                <div style={{ backgroundColor: '#fff', boxShadow: '2px 2px 2px -2px gray' }}>
                    {/* <h4 style={{ textAlign: 'center', fontSize: '26px', color: '#6496be', padding: '15px 0 0 0 ' }}>Onze Services:</h4> */}
                    <div className='services'>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '0 0 5px 20px' }}>
                            <StaticImage
                                src='../icons/expert.png'
                                quality={95}
                                formats={['auto', 'webp', 'avif']}
                                loading='eager'
                                alt='Intro banner'
                                style={{ width: '25px', marginRight: '10px', opacity: '0.5' }}
                            />
                            <div>{t('footer_feature1')}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '0 0 5px 20px' }}>
                            <StaticImage
                                src='../icons/travel.png'
                                quality={95}
                                formats={['auto', 'webp', 'avif']}
                                loading='eager'
                                alt='Intro banner'
                                style={{ width: '25px', marginRight: '10px', opacity: '0.5' }}
                            />
                            <div>{t('footer_feature2')}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '0 0 5px 20px' }}>
                            <StaticImage
                                src='../icons/immigration.png'
                                quality={95}
                                formats={['auto', 'webp', 'avif']}
                                loading='eager'
                                alt='Intro banner'
                                style={{ width: '25px', marginRight: '10px', opacity: '0.5', fill: 'yellow' }}
                            />
                            <div>{t('footer_feature3')}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '0 0 5px 20px' }}>
                            <StaticImage
                                src='../icons/pointer.png'
                                quality={95}
                                formats={['auto', 'webp', 'avif']}
                                loading='eager'
                                alt='Intro banner'
                                style={{ width: '25px', marginRight: '10px', opacity: '0.5' }}
                            />
                            <div>{t('footer_feature4')}</div>
                        </div>



                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', margin: '35px 0'}}>
                    <div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/contact/'>{t('footer_button')}</Link>
                    </div>
                </div>

                
            </div>
        </Layout>

    );
};




export default IndexPage;

